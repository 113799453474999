<template>
	<div>
		<!-- Body -->
		<section class="section">
			<div class="container">
				<div class="columns is-centered">
					<div class="column">
						<div class="has-text-centered mt-5">
							<div>
								<img src="@/assets/img/img_app-icon.svg" />
							</div>

							<div class="mt-5 is-size-5 has-text-weight-medium">
								Loading Morpher App
							</div>

							<h2 class="mt-5">Checking app install state..</h2>
							
        					<!-- <a :href="mobilePath" id="redirect-div">Click here if you not automatically redirected</a> -->
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>

import markets from '../services/Markets';
const valid_country = ['AL', 'AT', 'BA', 'BE', 'BG', 'BY', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IS', 'IE', 'IT', 'XK', 'LV', 'LT', 'LU', 'MT', 'MD', 'ME', 'MK', 'NO', 'NL', 'PL', 'PT', 'RO', 'RS', 'SK', 'SI', 'ES', 'SE', 'CH', 'RU', 'UA', 'GB', 'TR', 'CA', 'AR', 'BR', 'KY', 'CL', 'CO', 'CR', 'EC', 'MX', 'PE', 'UY', 'AU', 'KZ', 'KG', 'NZ', 'KR', 'SG', 'JP', 'IL', 'UZ', 'AE', 'SA', 'ZA', 'CI', 'SC', 'HK', 'MN', 'KW', 'BN', 'MV', 'KH', 'BZ', 'TC', 'TT', 'TW', 'BM', 'MY', 'VG', 'AI', 'BS', 'DO', 'MO', 'IN', 'NG', 'PK', 'MA', 'JM', 'ID', 'PH', 'TH', 'VN'];

export default {
	name: "MobileRedirect",
	components: {},
	data() {
		return {
			ip_location: "",
			mobilePath: '',
			appPath: '',
		};
	},
	async mounted() {
		document.dispatchEvent(new Event("x-app-rendered"));

		let path = this.$route.path.toLowerCase();

		if (path && path.substring(path.length - 1) == "/") {
			path = path.substring(0, path.length - 1);
		}



		let valid = ["/ape", "/hodl", "/trade_better" ];
		if (valid.includes(path)) {
			let url = process.env.VUE_APP_MOBILE_LINK || 'https://fjzk.adj.st{path}?adj_t=19ugb3o8'
			url = url.replace('{path}', path)
			url +=`&adj_fallback=https%3A%2F%2F${process.env.VUE_APP_REGISTER_URL.replace(/\//g,'%2F')}&adj_redirect_macos=https%3A%2F%2F${process.env.VUE_APP_REGISTER_URL.replace(/\//g,'%2F')}`

			const os = this.getMobileOperatingSystem()



			if (os !== 'unknown') {
				window.location.href = process.env.VUE_APP_MOBILE_DEEPLINK + 'home';
			} else {
				window.location.href = url;
			}
			setTimeout(() => {
				window.location.href = url;
			}, 500)
			
		} else {
			if (path.toLowerCase().includes("/r/trade/") || path.toLowerCase() == '/r/trade') {
				let parameters = window.location.search

				path = path.replace("/r/trade/", "")
				if (path.toLowerCase() == '/r/trade') {
					path = ''
				}

				let mobile_path = path;
				if (path.includes('stock/') || path.includes('crypto/') || path.includes('commodity/') || path.includes('forex/') || path.includes('index/') || path.includes('unique/') ) {
					
					mobile_path = 'market/'
					if (!parameters) {
						parameters = '?'
					} else {
						parameters += '&'
					}
					parameters += 'market_id=' + path.replace('/', '_').toUpperCase();
				}

				if (path === 'stock' || path === 'crypto' || path === 'commodity' || path === 'forex' || path === 'index' || path === 'unique') {
					mobile_path = 'trade/' + path
				}

				if (path.includes('settings/')) {
					path = path.substring(0, path.indexOf('/') )
				}

				if (mobile_path.includes('settings/')) {
					mobile_path = mobile_path.substring(mobile_path.indexOf('/') +1 )
				}

			

				let mobilePath = process.env.VUE_APP_MOBILE_DEEPLINK + mobile_path + (parameters ? parameters : '');
				let appPath = process.env.VUE_APP_APPLICATION_URL + '/' + path + (parameters ? parameters : '');
				if (mobilePath.includes('/list/')) {
					mobilePath = mobilePath.replace(/-/g, '_')
					if (mobilePath.includes('?')) {
						mobilePath = mobilePath.substring(0, mobilePath.indexOf('?'))
					}
				}

				const os = this.getMobileOperatingSystem()

				if (os !== 'unknown') {
					
					this.mobilePath = mobilePath
					this.appPath = appPath;

					window.location.href = this.mobilePath;

					window.setTimeout(()=> {
						window.location.href = this.appPath
					}, 2000)

				 } else {
				 	window.location.href = appPath;
				 }

			} else {
				const valid = ["/app_link", "/app_link_ios", "/app_link_android"]
				if (valid.includes(path)) {
					const os = this.getMobileOperatingSystem()
					let referred_by = (this.$route.params.referred_by || localStorage.getItem("airdrop_referred_by"));

					if (os == 'unknown' && path == "/app_link") {
						window.location.href = (process.env.VUE_APP_APPLICATION_URL || 'https://morpher.com/trade') + '/register'

						return;
					}

					if (os == 'unknown' && path == "/app_link_ios") {
						this.ip_location = await markets.checkLocation()
						let testFlight = true;
						if (this.ip_location && this.ip_location.country_code && valid_country.includes(this.ip_location.country_code.toUpperCase())) {
							testFlight = false
						}

						if (!testFlight) {
							window.location.href ='https://apps.apple.com/us/app/morpher/id6444658444';
						}

						return;

					}

					if (os == 'unknown' && path == "/app_link_android") {

						window.location.href ='https://play.google.com/store/apps/details?id=com.morpher.trade.beta'
						return;
					}
					let custom_invite = (this.$route.params.custom_invite || localStorage.getItem("airdrop_custom_invite"));

					if (referred_by) referred_by = referred_by.toLowerCase();
					if (custom_invite) referred_by = custom_invite.toLowerCase();
					

					let url = process.env.VUE_APP_MOBILE_LINK || 'https://fjzk.adj.st{path}?adj_t=19ugb3o8'
					url = url.replace('{path}', '/register')

					url +='&link=' + (process.env.VUE_APP_APPLICATION_URL || 'https://morpher.com/trade') + '/register'

					let urlWeb = (process.env.VUE_APP_APPLICATION_URL || 'https://morpher.com/trade') +	'/register'

					const utm_source = localStorage.getItem('utm_source');
					const utm_medium = localStorage.getItem('utm_medium');
					const utm_campaign = localStorage.getItem('utm_campaign');
					const utm_term = localStorage.getItem('utm_term');
					const utm_content = localStorage.getItem('utm_content');
					const at_gd = localStorage.getItem('at_gd');
					const utm_referrer = localStorage.getItem('utm_referrer');
					const ref = localStorage.getItem('ref');
					const source_id = localStorage.getItem('source_id');
					const affiliate_referrer = localStorage.getItem('affiliate_referrer');
					const affiliate_id = localStorage.getItem('affiliate_id');
					const affiliate_campaign = localStorage.getItem('affiliate_campaign');
					const affiliate_utm_source = localStorage.getItem('affiliate_utm_source');
					const affiliate_utm_medium = localStorage.getItem('affiliate_utm_medium');
					const affiliate_utm_campaign = localStorage.getItem('affiliate_utm_campaign');
					const affiliate_path = localStorage.getItem('affiliate_path');


					if (custom_invite || referred_by) {
						url += (custom_invite ? "&custom_invite=" + custom_invite : "&referred_by=" + referred_by);
						urlWeb += (custom_invite ? "/?custom_invite=" + custom_invite : "/?referred_by=" + referred_by);
					} else {
						urlWeb += '/?referred_by='
					}

					if (utm_campaign) url +='&utm_campaign=' + utm_campaign;
					if (utm_source) url +='&utm_source=' + utm_source;
					if (utm_medium) url +='&utm_medium=' + utm_medium;
					if (utm_term) url +='&utm_term=' + utm_term;
					if (utm_content) url +='&utm_content=' + utm_content;
					if (at_gd) url +='&at_gd=' + at_gd;
					if (utm_referrer) url +='&utm_referrer=' + utm_referrer;
					if (ref) url +='&ref=' + ref;
					if (source_id) url +='&source_id=' + source_id;
					if (affiliate_referrer) url +='&affiliate_referrer=' + affiliate_referrer;
					if (affiliate_id) url +='&affiliate_id=' + affiliate_id;
					if (affiliate_campaign) url +='&affiliate_campaign=' + affiliate_campaign;
					if (affiliate_utm_source) url +='&affiliate_utm_source=' + affiliate_utm_source;
					if (affiliate_utm_medium) url +='&affiliate_utm_medium=' + affiliate_utm_medium;
					if (affiliate_utm_campaign) url +='&affiliate_utm_campaign=' + affiliate_utm_campaign;
					if (affiliate_path) url +='&affiliate_path=' + affiliate_path;

					// if (path == "/app_link" || path == "/app_link_ios") {
					// 	url += '&ibi=com.morpher.trade.beta&isi=6444658444&ius=morpher'
					// }
					// if (path == "/app_link" || path == "/app_link_android") {
					// 	url += '&apn=com.morpher.trade.beta';
					// }

					// url += '&efr=1';

					this.ip_location = await markets.checkLocation()
					let testFlight = true;
					if (this.ip_location && this.ip_location.country_code && valid_country.includes(this.ip_location.country_code.toUpperCase())) {
						testFlight = false
					}
					// if (testFlight) {
					// 	// url += '&ifl=https://testflight.apple.com/join/bpqEWAq2';
					// 	url += '&ifl=' + urlWeb;
					// }

					window.location.href =url;
				} else {
					this.$router.push("/");
				}
			}
		}
	},
	methods: {
		getMobileOperatingSystem() {
			var userAgent = navigator.userAgent || navigator.vendor || window.opera;
			if( userAgent.match( /iPad/i ) || userAgent.match( /iPhone/i ) || userAgent.match( /iPod/i ) )
			{
				return 'ios';
			}
			else if( userAgent.match( /Android/i ) )
			{
				return 'android';
			}
			else
			{
				return 'unknown';
			}
		}
	},
	head() {
		return {
			title: {
				inner: "Mobile App Redirect",
			},
			meta: [
				{
					name: "description",
					content: "Mobile App Redirect.",
					id: "desc",
				},
			],
			link: [ ],
		};
	},
};
</script>

<style scoped>
.hero {
	background-color: #f6f7f8;
}
.mt-10 {
	margin-top: 10px;
}

.blog-container-column {
	max-width: 800px;
}
.blog {
	margin-top: 2rem;
	margin-bottom: 2rem;
}
.blog .title-font {
	font-size: 1.75rem;
}

.cta-btn.button {
	border-radius: 5px;
	border-width: 2px;
	color: #4c4c4c;
	font-weight: 700;
	font-size: 1.125rem;
	height: 2.875rem;
	padding: 0 2rem;
}
.cta-btn.button:hover {
	background-color: #00c386;
	border-color: #00c386;
	color: #fff;
	transition-delay: 0s;
	transition-duration: 0.35s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.cta-btn.button.is-primary {
	color: #fff;
}
.cta-btn.button.is-primary:hover {
	background-color: #00d492;
	border-color: #00d492;
	box-shadow: 0px 2px 4px rgba(0, 161, 111, 0.1),
		0px 4px 10px rgba(0, 161, 111, 0.2);
	transition-delay: 0s;
	transition-duration: 0.3s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
</style>
